<template lang="pug">
  .calendar
    CalendarHeader.sticky(
      :date-range="dateRange"
      :invalid-dates="invalidDates"
    )
    .calendar-body(v-if="!isEmpty(currentPlan)")
      .car-class-definition-wrapper
        CarClassDefinition(
          :current-plan="currentPlan"
          :filters="filters"
          :rental-periods="rentalPeriods"
        )
      ScrollSync.car-class-data-wrapper(
        horizontal
      )
        CarClassData(
          :current-plan="currentPlan"
          :changed-prices="changedPrices"
          :date-range="dateRange"
          :filters="filters"
          :rental-periods="rentalPeriods"
          :competitors="competitors"
          :invalid-prices="invalidPrices"
          :just-updated-dates="justUpdatedDates"
          @change="$emit('change', $event)"
        )
</template>

<script>
  import { isEmpty } from "lodash-es"

  export default {
    props: {
      currentPlan: {
        type: Object,
        default: () => new Object()
      },
      changedPrices: {
        type: Object,
        default: () => new Object()
      },
      dateRange: {
        type: Array,
        default: () => new Array()
      },
      filters: {
        type: Object,
        default: () => new Object()
      },
      rentalPeriods: {
        type: Array,
        default: () => new Array()
      },
      competitors: {
        type: Array,
        default: () => new Array()
      },
      invalidPrices: {
        type: Object,
        default: () => Array()
      },
      justUpdatedDates: {
        type: Set,
        default: () => new Set()
      }
    },

    components: {
      CalendarHeader: () => import("./CalendarHeader"),
      CarClassDefinition: () => import("./CarClassDefinition"),
      CarClassData: () => import("./CarClassData")
    },

    computed: {
      invalidDates() {
        return this.currentPlan.recommended_prices?.invalid_dates
      }
    },

    methods: {
      isEmpty
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .calendar
    margin: 30px 0
    position: relative

    .sticky
      position: sticky
      top: 0
      z-index: 10
      background: $default-white
      max-width: 100%
      padding-top: 10px
      width: fit-content

    .calendar-body
      display: flex

      .car-class-data-wrapper
        overflow-x: auto
</style>
